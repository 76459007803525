import { Component } from "react";
import { Link } from "react-scroll";
import { Button } from "antd";

export default class ScrollingTraitSectionRaces extends Component {
  render() {
    return (
      <ul
        style={{
          display: "flex",
          listStyle: "none",
          justifyContent: "space-around",
          paddingBottom: "10px",
        }}
      >
        {/* <li>
          <Link activeClass="active" to="home" spy={true} smooth={true}>
            Home
          </Link>
        </li> */}
        {/* <li>
          <Button>
            <Link to="FAQ" spy={true} smooth={true}>
              {" "}
              <DownCircleFilled></DownCircleFilled>
            </Link>
          </Button>
        </li> */}
        <p>
          <li>
            {" "}
            <Button shape="round" size="large" type="text">
              <Link to="Human" spy={true} smooth={true}>
                <img
                  src="https://ucarecdn.com/6861ee51-86e0-4552-8e2a-f122270212e2/-/resize/100x30/"
                  alt="HUMAN"
                  width={100}
                  height={30}
                ></img>
              </Link>
            </Button>
          </li>
          <li>
            {" "}
            <Button shape="round" size="large" type="text">
              <Link to="Norseman" spy={true} smooth={true}>
                <img
                  src="https://ucarecdn.com/4e3483e5-60d7-493c-9c59-0be880674c8c/-/resize/100x30/"
                  alt="NORSEMAN"
                  width={100}
                  height={30}
                ></img>
              </Link>
            </Button>
          </li>

          <li>
            {" "}
            <Button shape="round" size="large" type="text">
              <Link to="Elf" spy={true} smooth={true}>
                <img
                  src="https://ucarecdn.com/389e5ea9-7a83-4e7a-941d-9f788dbd9584/-/resize/100x30/"
                  alt="ELF"
                  width={100}
                  height={30}
                ></img>
              </Link>
            </Button>
          </li>
          <li>
            {" "}
            <Button shape="round" size="large" type="text">
              <Link to="Goblin" spy={true} smooth={true}>
                <img
                  src="https://ucarecdn.com/6b3cf0a6-1125-4680-9228-f1de3f3e12c0/-/resize/100x30/"
                  alt="GOBLIN"
                  width={100}
                  height={30}
                ></img>
              </Link>
            </Button>
          </li>
          <li>
            {" "}
            <Button shape="round" size="large" type="text">
              <Link to="Orc" spy={true} smooth={true}>
                <img
                  src="https://ucarecdn.com/0f688774-ecdf-48f9-96f5-7efbaa4afff4/-/resize/100x30/"
                  alt="Orc"
                  width={100}
                  height={30}
                ></img>
              </Link>
            </Button>
          </li>
          <li>
            {" "}
            <Button shape="round" size="large" type="text">
              <Link to="Skeleton" spy={true} smooth={true}>
                <img
                  src="https://ucarecdn.com/b2d10221-6e0c-4ed8-b218-94011cec0833/-/resize/100x30/"
                  alt="Skeleton"
                  width={100}
                  height={30}
                ></img>
              </Link>
            </Button>
          </li>
          <li>
            {" "}
            <Button shape="round" size="large" type="text">
              <Link to="Demon" spy={true} smooth={true}>
                <img
                  src="https://ucarecdn.com/a4e30a8d-1e97-42a3-98d7-857d2212537c/-/resize/100x30/"
                  alt="Demon"
                  width={100}
                  height={30}
                ></img>
              </Link>
            </Button>
          </li>
        </p>
      </ul>
    );
  }
}
