// import AvavoxLogoMono from "../Whitecombo.svg";

import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};
function AvavoxLogoHeaderMono() {
  return (
    <div>
      <Desktop>
        <img
          src="https://ucarecdn.com/76acb19a-3592-44ce-8399-3fd66573704d/-/resize/200x200/"
          alt="AvavoxLogo"
          width={200}
          height={200}
        />
      </Desktop>
      <Tablet>
        <img
          src="https://ucarecdn.com/76acb19a-3592-44ce-8399-3fd66573704d/-/resize/200x200/"
          alt="AvavoxLogo"
          width={200}
          height={200}
        />
      </Tablet>
      <Mobile>
        {" "}
        <img
          src="https://ucarecdn.com/76acb19a-3592-44ce-8399-3fd66573704d/-/resize/150x150/"
          alt="AvavoxLogo"
          width={150}
          height={150}
        />
      </Mobile>
    </div>
  );
}

export default AvavoxLogoHeaderMono;
