import React from "react";

import ReactApexChart from "react-apexcharts";

import { Button } from "antd";
class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [1423, 979, 928, 737, 659, 555, 274],
      options: {
        chart: {
          width: 380,
          type: "donut",
          foreColor: "#E7E7E7",
        },
        title: {
          text: "TOTAL RACE STATS",
          align: "center",
          margin: 10,
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Segoe UI",
          },
        },

        labels: ["Human", "Norseman", "Elf", "Goblin", "Orc", "Skeleton", "Demon"],

        colors: ["#b1a041", "#3e61ab", "#44ad90", "#8fb047", "#b07542", "#c43e75", "#6b40bd"],
        dataLabels: {
          enabled: true,
          // style: { colors: ["#E7E7E7"] },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: true,
                onItemClick: {
                  toggleDataSeries: true,
                },
                // onItemHover: {
                //   highlightDataSeries: true,
                // },
                markers: {
                  // width: 12,
                  // height: 12,
                  // strokeWidth: 0,
                  // strokeColor: '#fff',
                  // fillColors: undefined,
                  // radius: 12,
                  // customHTML: undefined,

                  onClick: function (chart, seriesIndex, opts) {
                    console.log("series- ", seriesIndex + "'s marker was clicked");
                  },
                  //     offsetX: 0,
                  //     offsetY: 0
                  // },
                },
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
      },
    };
  }

  appendData() {
    var arr = this.state.series.slice();
    arr.push(Math.floor(Math.random() * (100 - 1 + 1)) + 1);

    this.setState({
      series: arr,
    });
  }

  removeData() {
    if (this.state.series.length === 1) return;

    var arr = this.state.series.slice();
    arr.pop();

    this.setState({
      series: arr,
    });
  }

  randomize() {
    this.setState({
      series: this.state.series.map(function () {
        return Math.floor(Math.random() * (100 - 1 + 1)) + 1;
      }),
    });
  }

  reset() {
    this.setState({
      series: [1423, 979, 928, 737, 659, 555, 274],
      options: {
        chart: {
          width: 380,
          type: "donut",
        },
        title: {
          text: "TOTAL RACE STATS",
          align: "center",
          margin: 10,
        },
        labels: ["Human", "Norseman", "Elf", "Goblin", "Orc", "Skeleton", "Demon"],
        colors: ["#b1a041", "#3e61ab", "#44ad90", "#8fb047", "#b07542", "#c43e75", "#6b40bd"],
        dataLabels: {
          enabled: true,
          // style: { colors: ["#E7E7E7"] },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: true,
                onItemClick: {
                  toggleDataSeries: true,
                },
                // onItemHover: {
                //   highlightDataSeries: true,
                // },
                markers: {
                  // width: 12,
                  // height: 12,
                  // strokeWidth: 0,
                  // strokeColor: '#fff',
                  // fillColors: undefined,
                  // radius: 12,
                  // customHTML: undefined,

                  onClick: function (chart, seriesIndex, opts) {
                    console.log("series- ", seriesIndex + "'s marker was clicked");
                  },
                  //     offsetX: 0,
                  //     offsetY: 0
                  // },
                },
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
      },
    });
  }
  goRaceBreakdownHuman() {
    this.setState({
      series: [542, 360, 372, 111, 38],
      // series: [44, 55, 13, 33],
      options: {
        chart: {
          width: 380,
          type: "donut",
        },
        labels: ["Common", "Uncommon", "Rare", "Mystic", "Legendary"],
        colors: ["#a9a9a9", "#0096FF", "#12D900", "#f70d1a", "#9f00ff"],
        dataLabels: {
          enabled: true,
          // style: { colors: ["#E7E7E7"] },
        },
        title: {
          text: "HUMAN",
          align: "center",
          margin: 10,
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Segoe UI",
          },
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: true,
                onItemClick: {
                  toggleDataSeries: true,
                },
                // onItemHover: {
                //   highlightDataSeries: true,
                // },
                markers: {
                  // width: 12,
                  // height: 12,
                  // strokeWidth: 0,
                  // strokeColor: '#fff',
                  // fillColors: undefined,
                  // radius: 12,
                  // customHTML: undefined,

                  onClick: function (chart, seriesIndex, opts) {
                    console.log("series- ", seriesIndex + "'s marker was clicked");
                  },
                  //     offsetX: 0,
                  //     offsetY: 0
                  // },
                },
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
      },
    });
  }

  goRaceBreakdownNorseman() {
    this.setState({
      series: [281, 333, 184, 138, 43],
      // series: [44, 55, 13, 33],
      options: {
        chart: {
          width: 380,
          type: "donut",
        },
        title: {
          text: "NORSEMAN",
          align: "center",
          margin: 10,
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Segoe UI",
          },
        },
        labels: ["Common", "Uncommon", "Rare", "Mystic", "Legendary"],
        colors: ["#a9a9a9", "#0096FF", "#12D900", "#f70d1a", "#9f00ff"],
        dataLabels: {
          enabled: true,
          // style: { colors: ["#E7E7E7"] },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: true,
                onItemClick: {
                  toggleDataSeries: true,
                },
                // onItemHover: {
                //   highlightDataSeries: true,
                // },
                markers: {
                  // width: 12,
                  // height: 12,
                  // strokeWidth: 0,
                  // strokeColor: '#fff',
                  // fillColors: undefined,
                  // radius: 12,
                  // customHTML: undefined,

                  onClick: function (chart, seriesIndex, opts) {
                    console.log("series- ", seriesIndex + "'s marker was clicked");
                  },
                  //     offsetX: 0,
                  //     offsetY: 0
                  // },
                },
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
      },
    });
  }

  goRaceBreakdownElf() {
    this.setState({
      series: [452, 160, 192, 93, 31],

      // series: [44, 55, 13, 33],
      options: {
        chart: {
          width: 380,
          type: "donut",
        },
        title: {
          text: "ELF",
          align: "center",
          margin: 10,
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Segoe UI",
          },
        },
        labels: ["Common", "Uncommon", "Rare", "Mystic", "Legendary"],

        colors: ["#a9a9a9", "#0096FF", "#12D900", "#f70d1a", "#9f00ff"],
        dataLabels: {
          enabled: true,
          // style: { colors: ["#E7E7E7"] },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: true,
                onItemClick: {
                  toggleDataSeries: true,
                },
                // onItemHover: {
                //   highlightDataSeries: true,
                // },
                markers: {
                  // width: 12,
                  // height: 12,
                  // strokeWidth: 0,
                  // strokeColor: '#fff',
                  // fillColors: undefined,
                  // radius: 12,
                  // customHTML: undefined,

                  onClick: function (chart, seriesIndex, opts) {
                    console.log("series- ", seriesIndex + "'s marker was clicked");
                  },
                  //     offsetX: 0,
                  //     offsetY: 0
                  // },
                },
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
      },
    });
  }

  goRaceBreakdownGoblin() {
    this.setState({
      series: [287, 185, 158, 88, 19],
      // series: [44, 55, 13, 33],
      options: {
        chart: {
          width: 380,
          type: "donut",
        },
        title: {
          text: "GOBLIN",
          align: "center",
          margin: 10,
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Segoe UI",
          },
        },
        labels: ["Common", "Uncommon", "Rare", "Mystic", "Legendary"],

        colors: ["#a9a9a9", "#0096FF", "#12D900", "#f70d1a", "#9f00ff"],
        dataLabels: {
          enabled: true,
          // style: { colors: ["#E7E7E7"] },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: true,
                onItemClick: {
                  toggleDataSeries: true,
                },
                // onItemHover: {
                //   highlightDataSeries: true,
                // },
                markers: {
                  // width: 12,
                  // height: 12,
                  // strokeWidth: 0,
                  // strokeColor: '#fff',
                  // fillColors: undefined,
                  // radius: 12,
                  // customHTML: undefined,

                  onClick: function (chart, seriesIndex, opts) {
                    console.log("series- ", seriesIndex + "'s marker was clicked");
                  },
                  //     offsetX: 0,
                  //     offsetY: 0
                  // },
                },
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
      },
    });
  }

  goRaceBreakdownOrc() {
    this.setState({
      series: [152, 217, 151, 111, 28],
      // series: [44, 55, 13, 33],
      options: {
        chart: {
          width: 380,
          type: "donut",
        },
        title: {
          text: "ORC",
          align: "center",
          margin: 10,
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Segoe UI",
          },
        },
        labels: ["Common", "Uncommon", "Rare", "Mystic", "Legendary"],

        colors: ["#a9a9a9", "#0096FF", "#12D900", "#f70d1a", "#9f00ff"],
        dataLabels: {
          enabled: true,
          // style: { colors: ["#E7E7E7"] },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: true,
                onItemClick: {
                  toggleDataSeries: true,
                },
                // onItemHover: {
                //   highlightDataSeries: true,
                // },
                markers: {
                  // width: 12,
                  // height: 12,
                  // strokeWidth: 0,
                  // strokeColor: '#fff',
                  // fillColors: undefined,
                  // radius: 12,
                  // customHTML: undefined,

                  onClick: function (chart, seriesIndex, opts) {
                    console.log("series- ", seriesIndex + "'s marker was clicked");
                  },
                  //     offsetX: 0,
                  //     offsetY: 0
                  // },
                },
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
      },
    });
  }

  goRaceBreakdownSkeleton() {
    this.setState({
      series: [171, 146, 145, 72, 21],
      // series: [44, 55, 13, 33],
      options: {
        chart: {
          width: 380,
          type: "donut",
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Segoe UI",
          },
        },
        title: {
          text: "SKELETON",
          align: "center",
          margin: 10,
        },
        labels: ["Common", "Uncommon", "Rare", "Mystic", "Legendary"],

        colors: ["#a9a9a9", "#0096FF", "#12D900", "#f70d1a", "#9f00ff"],
        dataLabels: {
          enabled: true,
          // style: { colors: ["#E7E7E7"] },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: true,
                onItemClick: {
                  toggleDataSeries: true,
                },
                // onItemHover: {
                //   highlightDataSeries: true,
                // },
                markers: {
                  // width: 12,
                  // height: 12,
                  // strokeWidth: 0,
                  // strokeColor: '#fff',
                  // fillColors: undefined,
                  // radius: 12,
                  // customHTML: undefined,

                  onClick: function (chart, seriesIndex, opts) {
                    console.log("series- ", seriesIndex + "'s marker was clicked");
                  },
                  //     offsetX: 0,
                  //     offsetY: 0
                  // },
                },
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
      },
    });
  }

  goRaceBreakdownDemon() {
    this.setState({
      series: [61, 48, 71, 64, 30],
      // series: [44, 55, 13, 33],
      options: {
        chart: {
          width: 380,
          type: "donut",
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Segoe UI",
          },
        },
        title: {
          text: "DEMON",
          align: "center",
          margin: 10,
        },
        labels: ["Common", "Uncommon", "Rare", "Mystic", "Legendary"],

        colors: ["#a9a9a9", "#0096FF", "#12D900", "#f70d1a", "#9f00ff"],
        dataLabels: {
          enabled: true,
          // style: { colors: ["#E7E7E7"] },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: true,
                onItemClick: {
                  toggleDataSeries: true,
                },
                // onItemHover: {
                //   highlightDataSeries: true,
                // },
                markers: {
                  // width: 12,
                  // height: 12,
                  // strokeWidth: 0,
                  // strokeColor: '#fff',
                  // fillColors: undefined,
                  // radius: 12,
                  // customHTML: undefined,

                  onClick: function (chart, seriesIndex, opts) {
                    console.log("series- ", seriesIndex + "'s marker was clicked");
                  },
                  //     offsetX: 0,
                  //     offsetY: 0
                  // },
                },
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
      },
    });
  }

  render() {
    return (
      <div>
        <div class="chart-wrap">
          <div id="chart">
            <ReactApexChart options={this.state.options} series={this.state.series} type="donut" width={380} />
          </div>
        </div>

        <div class="actions">
          {/* <button onClick={() => this.appendData()}>+ ADD</button>
          &nbsp; */}
          <Button shape="round" size="large" type="text" onClick={() => this.goRaceBreakdownHuman()}>
            <img
              src="https://ucarecdn.com/6861ee51-86e0-4552-8e2a-f122270212e2/-/resize/100x30/"
              alt="HUMAN"
              width={100}
              height={30}
            ></img>
          </Button>
          <Button shape="round" size="large" type="text" onClick={() => this.goRaceBreakdownNorseman()}>
            <img
              src="https://ucarecdn.com/4e3483e5-60d7-493c-9c59-0be880674c8c/-/resize/100x30/"
              alt="NORSEMAN"
              width={100}
              height={30}
            ></img>
          </Button>

          <Button shape="round" size="large" type="text" onClick={() => this.goRaceBreakdownElf()}>
            <img
              src="https://ucarecdn.com/389e5ea9-7a83-4e7a-941d-9f788dbd9584/-/resize/100x30/"
              alt="ELF"
              width={100}
              height={30}
            ></img>
          </Button>

          <Button shape="round" size="large" type="text" onClick={() => this.goRaceBreakdownGoblin()}>
            <img
              src="https://ucarecdn.com/6b3cf0a6-1125-4680-9228-f1de3f3e12c0/-/preview/-/resize/100x30/"
              alt="GOBLIN"
              width={100}
              height={30}
            ></img>
          </Button>

          <Button shape="round" size="large" type="text" onClick={() => this.goRaceBreakdownOrc()}>
            <img
              src="https://ucarecdn.com/0f688774-ecdf-48f9-96f5-7efbaa4afff4/-/preview/-/resize/100x30/"
              alt="ORC"
              width={100}
              height={30}
            ></img>
          </Button>

          <Button shape="round" size="large" type="text" onClick={() => this.goRaceBreakdownSkeleton()}>
            <img
              src="https://ucarecdn.com/b2d10221-6e0c-4ed8-b218-94011cec0833/-/preview/-/resize/100x30/"
              alt="SKELETON"
              width={100}
              height={30}
            ></img>
          </Button>

          <Button shape="round" size="large" type="text" onClick={() => this.goRaceBreakdownDemon()}>
            <img
              src="https://ucarecdn.com/a4e30a8d-1e97-42a3-98d7-857d2212537c/-/preview/-/resize/100x30/"
              alt="DEMON"
              width={100}
              height={30}
            ></img>
          </Button>

          {/* { <button onClick={() => this.removeData()}>- REMOVE</button>
          &nbsp;
          <button onClick={() => this.randomize()}>RANDOMIZE</button>
          &nbsp; */}
          <Button shape="round" size="large" type="text" onClick={() => this.reset()}>
            <img
              src="https://ucarecdn.com/6b1b53c0-d9d1-4f6c-9a74-78d865f47754/-/preview/-/resize/100x30/"
              alt="RESET"
              width={100}
              height={30}
            ></img>
          </Button>
        </div>
      </div>
    );
  }
}

// const domContainer = document.querySelector("#app");
// ReactDOM.render(React.createElement(ApexChart), domContainer);
export default ApexChart;
