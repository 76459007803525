import React from "react";
import { styled, keyframes } from "@stitches/react";
import { violet, blackA, mauve } from "@radix-ui/colors";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import * as AccordionPrimitive from "@radix-ui/react-accordion";

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-accordion-content-height)" },
});

const slideUp = keyframes({
  from: { height: "var(--radix-accordion-content-height)" },
  to: { height: 0 },
});

const StyledAccordion = styled(AccordionPrimitive.Root, {
  borderRadius: 6,
  width: 500,
  backgroundColor: mauve.mauve6,
  boxShadow: `0 2px 10px ${blackA.blackA4}`,
});

const StyledItem = styled(AccordionPrimitive.Item, {
  overflow: "hidden",
  marginTop: 1,

  "&:first-child": {
    marginTop: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },

  "&:last-child": {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },

  "&:focus-within": {
    position: "relative",
    zIndex: 1,
    boxShadow: `0 0 0 2px ${mauve.mauve12}`,
  },
});

const StyledHeader = styled(AccordionPrimitive.Header, {
  all: "unset",
  display: "flex",
});

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: "unset",
  fontFamily: "inherit",
  backgroundColor: "transparent",
  padding: "0 20px",
  height: 100,
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: 20,
  lineHeight: 1,
  color: "#ffffff",
  boxShadow: `0 1px 0 ${mauve.mauve6}`,
  '&[data-state="closed"]': { backgroundColor: "#e84142" },
  '&[data-state="open"]': { backgroundColor: "#e84142" },
  "&:hover": { backgroundColor: "#e84142" },
});

const StyledContent = styled(AccordionPrimitive.Content, {
  overflow: "hidden",
  fontSize: 15,
  color: mauve.mauve11,
  backgroundColor: mauve.mauve2,

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

const StyledContentText = styled("div", {
  padding: "15px 20px",
});

const StyledChevron = styled(ChevronDownIcon, {
  color: "#ffffff",
  transition: "transform 300ms cubic-bezier(0.87, 0, 0.13, 1)",
  "[data-state=open] &": { transform: "rotate(180deg)" },
});

// Exports
export const Accordion = StyledAccordion;
export const AccordionItem = StyledItem;
export const AccordionTrigger = React.forwardRef(({ children, ...props }, forwardedRef) => (
  <StyledHeader>
    <StyledTrigger {...props} ref={forwardedRef}>
      {children}
      <StyledChevron aria-hidden />
    </StyledTrigger>
  </StyledHeader>
));
export const AccordionContent = React.forwardRef(({ children, ...props }, forwardedRef) => (
  <StyledContent {...props} ref={forwardedRef}>
    <StyledContentText>{children}</StyledContentText>
  </StyledContent>
));

// Your app...
export const Tokenomics = () => (
  <Accordion type="single" collapsible>
    <AccordionItem value="item-1">
      <AccordionTrigger>
        {" "}
        <span style={{ fontWeight: "bold" }}>The earlier you get in, the better...</span>
      </AccordionTrigger>
      <AccordionContent>
        {" "}
        We will give back to early adopters. The first 2777 will start on a higher price per NFT, but they will unlock
        rewards as more AVOXs are sold.
      </AccordionContent>
    </AccordionItem>

    <AccordionItem value="item-2">
      <AccordionTrigger>
        <span style={{ fontWeight: "bold" }}>Rewards decrease over time so act quick!</span>
      </AccordionTrigger>
      <AccordionContent>
        {" "}
        Initially, there's a higher reward that makes earlier purchases cheaper. As more AVOXs are sold, the reward
        decreases faster than the NFT price which effectively makes the actual paid price higher. People who buy early
        get a higher discount and after the first 2777, the discount goes to zero.
      </AccordionContent>
    </AccordionItem>

    <AccordionItem value="item-3">
      <AccordionTrigger>
        <span style={{ fontWeight: "bold" }}> Reward distribution...</span>
      </AccordionTrigger>
      <AccordionContent>
        The number of NFT's sold and the percentage of rewards received is a linear correlation. For example, when 50%
        are sold, you will be eligible to claim 50% of your rewards.
      </AccordionContent>
    </AccordionItem>
    <AccordionItem value="item-4">
      <AccordionTrigger>
        <span style={{ fontWeight: "bold" }}> 3% Royalties for original minters...</span>
      </AccordionTrigger>
      <AccordionContent>
        The royalties are set to the EIP2981 standard. Since it's not an enforceable standard, any compliant marketplace
        will be able to "respect" it if they choose. Unfortunately, we have no control over what other marketplaces will
        do in the future. However, we will initially be choosing a well known marketplace to support us who has
        respected these in the past. More details on this will be announced in the future.
      </AccordionContent>
    </AccordionItem>
  </Accordion>
);

export default Tokenomics;
