// import AvavoxLogo from "../RedCombo.svg";

// import AvavoxLogo from "../GlowingAvavoxLogo.gif";

function AvavoxLogoHeader() {
  return (
    // <img
    //   // src={AvavoxLogo}
    //   // src="https://ucarecdn.com/4adbbdf0-8641-4589-86cd-bb5bf76206d0/"

    //   alt="AvavoxLogo"
    //   // width={300}
    //   // height={300}

    //   width={250}
    //   height={250}
    // />
    <div onContextMenu={e => e.preventDefault()} style={{ width: "100%" }}>
      <video autoPlay loop muted>
        <source src="https://ucarecdn.com/4adbbdf0-8641-4589-86cd-bb5bf76206d0/" type="video/webm" />
        Sorry, your browser doesn't support videos.
      </video>
    </div>
  );
}

export default AvavoxLogoHeader;
