// import AvavoxLogo from "../RedCombo.svg";

import { Link } from "react-router-dom";
function AvavoxLogoHeaderSmall() {
  return (
    <a href="https://avavox.art/">
      <img
        src="https://ucarecdn.com/3e321958-cec7-44f7-84fa-5f257580d907/"
        alt="AvavoxLogo"
        width={200}
        height={100}
        style={{ marginTop: "15px" }}
        // href="https://avavox.art/"

        //width={200} height={200}
      />
    </a>
  );
}

export default AvavoxLogoHeaderSmall;
