// import RewardGraph from "../Rewards2.png";
function RewardGraphPicture() {
  return (
    <img
      width="500"
      height="300"
      src="https://ucarecdn.com/6891f3fd-66f8-461f-97b1-6dde1e208818/-/resize/500x350/"
      alt="Banner"
    />
  );
}

export default RewardGraphPicture;
