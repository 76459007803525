// import banner from "../banner.jfif";
function Banner() {
  return (
    <img
      fetchpriority="high"
      width="992"
      height="336"
      src="https://ucarecdn.com/ec87fb39-8179-4db6-b98b-98e7eaaa6805/"
      alt="Banner"
    />
  );
}

export default Banner;
