const voxels = " who are crunching away on the ";
const avalanche = "Avalanche ";
const metaverse = "Blockchain waiting to enter the Metaverse.";
const explanation =
  "\nEach AvaVox is randomly generated from numerous physical attributes and colors, ranging from Orcs, Elves, to Skeletons and Demons. \nAll are unique combinations, but some are truly legendary.";

let explanationSplit = explanation.split("\n").map(i => {
  return <p> {i}</p>;
});
const intro = "is a collection of ";

const total = "5,555 unique voxel NFTs";

export default function Intro() {
  return (
    <p>
      <b>AvaVox</b> {intro} <b>{total}</b> {voxels} <b>{avalanche}</b>
      {metaverse} {"\n"}
      <p>{explanationSplit}</p>
    </p>
  );
}
