import React, { Component } from "react";
import { Link } from "react-scroll";
import { Button } from "antd";

export default class ScrollingTraitSectionTop extends Component {
  render() {
    return (
      <ul
        style={{
          display: "flex",
          listStyle: "none",
          justifyContent: "space-around",
        }}
      >
        {/* <li>
          <Link activeClass="active" to="home" spy={true} smooth={true}>
            Home
          </Link>
        </li> */}
        <li>
          <Button type="text">
            <Link to="Traits" spy={true} smooth={true}>
              {" "}
              <img
                src="https://ucarecdn.com/767a3ef1-82d8-42f9-b26c-b30b42ea114a/-/resize/200x50/"
                alt="traits"
                width={200}
                height={50}
              ></img>
            </Link>
          </Button>
        </li>
      </ul>
    );
  }
}
