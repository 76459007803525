// import AvavoxLogo from "../RedCombo.svg";

// import AvavoxLogo from "../GlowingAvavoxLogo.gif";

import { Button } from "antd";
import { Link } from "react-router-dom";
function MintingButton() {
  return (
    <span
      id="MintingButton"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "20px",
        marginLeft: "50px",
        textAlign: "left",
        marginBottom: "50px",
      }}
    >
      <Button
        style={{
          verticalAlign: "top",
          marginLeft: 4,
          marginTop: 2,
          // boxShadow: "0 0 60px whitesmoke",
          // boxShadow: "20px 0 80px #f0f",
        }}
        shape="round"
        size="large"
        type="text"
        /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
      >
        <Link to="/minting">
          <img
            style={{
              boxShadow: "0 0 60px whitesmoke",
              boxShadow: "20px 0 80px #f0f",
              boxShadow: "-20px 0 80px #0ff",
              boxShadow: "20px 0 300px #f0f",
              boxShadow: "-20px 0 300px #0ff, 0 0 50px #fff,-10px 0 80px #f0f,10px 0 80px #0ff",
            }}
            src="https://ucarecdn.com/11db1ccf-179d-4e30-ba13-55fabdd669d1/-/resize/300x80/"
            alt="Mint"
            width={300}
            height={80}
          ></img>
        </Link>
      </Button>
    </span>
  );
}

export default MintingButton;
