// import TopBarTraitsButton from "./ButtonTraits.png";

import { Link } from "react-router-dom";
function TopBarTraitsButtonSmall() {
  return (
    <a href="https://avavox.art/">
      <img
        src="https://ucarecdn.com/23eaa413-b096-42ca-8f8e-be33836b4a35/-/resize/200x100/"
        alt="TopBarTraitsButton"
        width={200}
        height={100}
        // href="https://avavox.art/"

        //width={200} height={200}
      />
    </a>
  );
}

export default TopBarTraitsButtonSmall;
