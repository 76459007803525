import React from "react";
import { styled, keyframes } from "@stitches/react";
import { blackA, mauve } from "@radix-ui/colors";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import WalletOptions from "./WalletOptions";
const mintPrice = 4;

const mintPriceAdjusted = 3.5;
const slideDown = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-accordion-content-height)" },
});

const slideUp = keyframes({
  from: { height: "var(--radix-accordion-content-height)" },
  to: { height: 0 },
});

const StyledAccordion = styled(AccordionPrimitive.Root, {
  borderRadius: 6,
  width: 500,
  backgroundColor: mauve.mauve6,
  boxShadow: `0 2px 10px ${blackA.blackA4}`,
});

const StyledItem = styled(AccordionPrimitive.Item, {
  overflow: "hidden",
  marginTop: 1,

  "&:first-child": {
    marginTop: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },

  "&:last-child": {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },

  "&:focus-within": {
    position: "relative",
    zIndex: 1,
    boxShadow: `0 0 0 2px ${mauve.mauve12}`,
  },
});

const StyledHeader = styled(AccordionPrimitive.Header, {
  all: "unset",
  display: "flex",
});

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: "unset",
  fontFamily: "inherit",
  backgroundColor: "transparent",
  padding: "0 20px",
  height: 100,
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: 20,
  lineHeight: 1,
  color: "#ffffff",
  boxShadow: `0 1px 0 ${mauve.mauve6}`,
  '&[data-state="closed"]': { backgroundColor: "#e84142" },
  '&[data-state="open"]': { backgroundColor: "#e84142" },
  "&:hover": { backgroundColor: "#e84142" },
});

const StyledContent = styled(AccordionPrimitive.Content, {
  overflow: "hidden",
  fontSize: 15,
  color: mauve.mauve11,
  backgroundColor: mauve.mauve2,

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

const StyledContentText = styled("div", {
  padding: "15px 20px",
});

const StyledChevron = styled(ChevronDownIcon, {
  color: "#ffffff",
  transition: "transform 300ms cubic-bezier(0.87, 0, 0.13, 1)",
  "[data-state=open] &": { transform: "rotate(180deg)" },
});

// Exports
export const Accordion = StyledAccordion;
export const AccordionItem = StyledItem;
export const AccordionTrigger = React.forwardRef(({ children, ...props }, forwardedRef) => (
  <StyledHeader>
    <StyledTrigger {...props} ref={forwardedRef}>
      {children}
      <StyledChevron aria-hidden />
    </StyledTrigger>
  </StyledHeader>
));
export const AccordionContent = React.forwardRef(({ children, ...props }, forwardedRef) => (
  <StyledContent {...props} ref={forwardedRef}>
    <StyledContentText>{children}</StyledContentText>
  </StyledContent>
));

// Your app...
export const FAQMinting = () => (
  <Accordion type="single" collapsible>
    <AccordionItem value="item-5">
      <AccordionTrigger>
        <span style={{ fontWeight: "bold" }}>I get a grey overlay when I try connecting my wallet!</span>
      </AccordionTrigger>
      <AccordionContent>
        <WalletOptions></WalletOptions>
        <p>
          {" "}
          Only one of these should be enabled in your Chrome/FireFox extensions as it checks for an injected wallet
          provider.
        </p>
        <p>
          The reasoning is only one of these can be the dominant choice for connecting. Ensure you are not in incognito
          mode so the extensions work.
        </p>
      </AccordionContent>
    </AccordionItem>
    <AccordionItem value="item-6">
      <AccordionTrigger>
        <span style={{ fontWeight: "bold" }}>When I try to mint nothing pops up?</span>
      </AccordionTrigger>
      <AccordionContent>
        {" "}
        This is because you haven't got enough AVAX to mint for the quantity you require, please ensure you have the
        correct amount of funds required and then try again.
      </AccordionContent>
    </AccordionItem>
    <AccordionItem value="item-7">
      <AccordionTrigger>
        <span style={{ fontWeight: "bold" }}>I get a error message about JSON RPC failed when I try to mint?</span>
      </AccordionTrigger>
      <AccordionContent>
        {" "}
        Try clearing and rentering the mint quantity and then try minting again - if you have the correct amount of AVAX
        in your wallet for the quantity you are purchasing it should now succeed.
      </AccordionContent>
    </AccordionItem>
  </Accordion>
);

export default FAQMinting;
