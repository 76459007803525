import React, { Component } from "react";

import { Button } from "antd";

// import TopBarTraitsButton from "../components/TopBarTraitsButton";

import { Link } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";

import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};

export default class ScrollingHomeSectionTopMenu extends Component {
  render() {
    const handleClick = event => {
      console.log(event.target);
      console.log("Image clicked");
    };

    const scrollToTop = event => {
      setTimeout(function () {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, 500);
      // 👇️ scroll to top on page load

      console.log("Scroll");
    };

    const currentURL = window.location.href;
    // returns the absolute URL of a page
    const pathname = window.location.pathname;
    //returns the current url minus the domain nameexport default class ScrollingHomeSectionTopMenu extends Component {
    // const scrollTo = () => {
    //   let i = (
    //     <LinkScroll to="tokenomics" spy={true} smooth={true}>
    //       TOKENOMICS & REWARDS
    //     </LinkScroll>
    //   );
    //   return i;
    // };

    if (pathname === "/") {
      // console.log("current URL: " + currentURL + " pathname: " + pathname);
      return [
        <Desktop>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <Link to="/traits">
              <img
                src="https://ucarecdn.com/23eaa413-b096-42ca-8f8e-be33836b4a35/-/resize/100x30/"
                alt="traits"
                width={100}
                height={30}
                onClick={(handleClick, scrollToTop)}
              ></img>
            </Link>
          </Button>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"

            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
            // onClick={loadWeb3Modal}
          >
            <LinkScroll to="tiers" spy={true} smooth={true}>
              <img
                src="https://ucarecdn.com/af0301b5-3856-44f2-a135-3ee26359aaaa/-/resize/100x30/"
                alt="Tiers"
                width={100}
                height={30}
                onClick={handleClick}
              ></img>
            </LinkScroll>{" "}
            {/* <UpCircleFilled></UpCircleFilled> */}
          </Button>

          <Button
            shape="round"
            size="large"
            type="text"

            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
            // onClick={loadWeb3Modal}
          >
            <LinkScroll to="tokenomics" spy={true} smooth={true}>
              <img
                src="https://ucarecdn.com/e4cba88c-8912-442f-b396-cfe6a43290e4/-/preview/-/resize/100x30/"
                alt="Rewards"
                width={100}
                height={30}
                onClick={handleClick}
              ></img>
            </LinkScroll>{" "}
            {/* <UpCircleFilled></UpCircleFilled> */}
          </Button>
          {/* <Button style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }} shape="round" size="large" type="text">
            <Link to="/minting">
              <img
                src="https://ucarecdn.com/20d2550f-fbaf-459b-bca9-5d31baf12e10/-/resize/100x30/"
                alt="minting"
                width={100}
                height={30}
                onClick={(handleClick, scrollToTop)}
              ></img>
            </Link>
          </Button> */}
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/avavoxnfts">
              <img
                src="https://ucarecdn.com/18bdabca-7e96-4b9e-b7e9-dcf7a7e21b1b/-/preview/-/resize/40x30/"
                alt="twitter"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/mMjU2EwS4W">
              <img
                src="https://ucarecdn.com/2baa9429-c436-4402-a758-eec93ad64d93/-/preview/-/resize/40x30/"
                alt="discord"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
        </Desktop>,
        <Mobile>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"

            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
            // onClick={loadWeb3Modal}
          >
            <LinkScroll to="tiers" spy={true} smooth={true}>
              <img
                src="https://ucarecdn.com/af0301b5-3856-44f2-a135-3ee26359aaaa/-/preview/-/resize/100x30/"
                alt="Tiers"
                width={100}
                height={30}
                onClick={handleClick}
              ></img>
            </LinkScroll>{" "}
            {/* <UpCircleFilled></UpCircleFilled> */}
          </Button>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/avavoxnfts">
              <img
                src="https://ucarecdn.com/18bdabca-7e96-4b9e-b7e9-dcf7a7e21b1b/-/resize/40x30/"
                alt="twitter"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/mMjU2EwS4W">
              <img
                src="https://ucarecdn.com/2baa9429-c436-4402-a758-eec93ad64d93/-/preview/-/resize/40x30/"
                alt="discord"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
        </Mobile>,

        <Tablet>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"

            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
            // onClick={loadWeb3Modal}
          >
            <LinkScroll to="tiers" spy={true} smooth={true}>
              <img
                src="https://ucarecdn.com/af0301b5-3856-44f2-a135-3ee26359aaaa/-/preview/-/resize/100x30/"
                alt="Tiers"
                width={100}
                height={30}
                onClick={handleClick}
              ></img>
            </LinkScroll>{" "}
            {/* <UpCircleFilled></UpCircleFilled> */}
          </Button>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/avavoxnfts">
              <img
                src="https://ucarecdn.com/18bdabca-7e96-4b9e-b7e9-dcf7a7e21b1b/-/resize/40x30/"
                alt="twitter"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/mMjU2EwS4W">
              <img
                src="https://ucarecdn.com/2baa9429-c436-4402-a758-eec93ad64d93/-/preview/-/resize/40x30/"
                alt="discord"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
        </Tablet>,
      ];

      //   <Button
      //     style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
      //     shape="round"
      //     size="large"
      //     /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
      //     // onClick={loadWeb3Modal}
      //   >
      //     <LinkScroll to="Tokenomics">TOKENOMICS & REWARDS</LinkScroll> {/* <UpCircleFilled></UpCircleFilled> */}
      //   </Button>
      // )
    } else if (pathname === "/traits") {
      console.log("current URL: " + currentURL + " pathname: " + pathname);
      return [
        <Desktop>
          <Button
            style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
            // onClick={loadWeb3Modal}
          >
            <Link to="/">
              <img
                src="https://ucarecdn.com/54914285-8c51-44ad-b44f-b3b3c065e8d7/-/preview/-/resize/100x30/"
                alt="AVAVOX"
                width={100}
                height={30}
                onClick={handleClick}
              ></img>
            </Link>{" "}
            {/* <UpCircleFilled></UpCircleFilled> */}
          </Button>
          {/* <Button style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }} shape="round" size="large" type="text">
            <Link to="/minting">
              <img
                src="https://ucarecdn.com/20d2550f-fbaf-459b-bca9-5d31baf12e10/-/resize/100x30/"
                alt="minting"
                width={100}
                height={30}
                onClick={(handleClick, scrollToTop)}
              ></img>
            </Link>
          </Button> */}
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/avavoxnfts">
              <img
                src="https://ucarecdn.com/18bdabca-7e96-4b9e-b7e9-dcf7a7e21b1b/-/preview/-/resize/40x30/"
                alt="twitter"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/mMjU2EwS4W">
              <img
                src="https://ucarecdn.com/2baa9429-c436-4402-a758-eec93ad64d93/-/preview/-/resize/40x30/"
                alt="discord"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
        </Desktop>,

        <Tablet>
          <Button
            style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
            // onClick={loadWeb3Modal}
          >
            <Link to="/">
              <img
                src="https://ucarecdn.com/54914285-8c51-44ad-b44f-b3b3c065e8d7/-/preview/-/resize/100x30/"
                alt="AVAVOX"
                width={100}
                height={30}
                onClick={handleClick}
              ></img>
            </Link>{" "}
            {/* <UpCircleFilled></UpCircleFilled> */}
          </Button>

          {
            /* <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
            // >
            //   <Link to="/minting">
            //     <img
            //       src="https://ucarecdn.com/20d2550f-fbaf-459b-bca9-5d31baf12e10/-/resize/100x30/"
            //       alt="minting"
            //       width={100}
            //       height={30}
            //       onClick={(handleClick, scrollToTop)}
            //     ></img>
            //   </Link>
            // </Button> */}
          }
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/avavoxnfts">
              <img
                src="https://ucarecdn.com/18bdabca-7e96-4b9e-b7e9-dcf7a7e21b1b/-/preview/-/resize/40x30/"
                alt="twitter"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/mMjU2EwS4W">
              <img
                src="https://ucarecdn.com/2baa9429-c436-4402-a758-eec93ad64d93/-/preview/-/resize/40x30/"
                alt="discord"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
        </Tablet>,

        <Mobile>
          <Button
            style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
            // onClick={loadWeb3Modal}
          >
            <Link to="/">
              <img
                src="https://ucarecdn.com/54914285-8c51-44ad-b44f-b3b3c065e8d7/-/preview/-/resize/100x30/"
                alt="AVAVOX"
                width={100}
                height={30}
                onClick={handleClick}
              ></img>
            </Link>{" "}
            {/* <UpCircleFilled></UpCircleFilled> */}
          </Button>

          {
            /* <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
            // >
            //   <Link to="/minting">
            //     <img
            //       src="https://ucarecdn.com/20d2550f-fbaf-459b-bca9-5d31baf12e10/-/resize/100x30/"
            //       alt="minting"
            //       width={100}
            //       height={30}
            //       onClick={(handleClick, scrollToTop)}
            //     ></img>
            //   </Link>
            // </Button> */}
          }
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/avavoxnfts">
              <img
                src="https://ucarecdn.com/18bdabca-7e96-4b9e-b7e9-dcf7a7e21b1b/-/preview/-/resize/40x30/"
                alt="twitter"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/mMjU2EwS4W">
              <img
                src="https://ucarecdn.com/2baa9429-c436-4402-a758-eec93ad64d93/-/preview/-/resize/40x30/"
                alt="discord"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
        </Mobile>,
      ];
    } else if (pathname === "/minting") {
      console.log("current URL: " + currentURL + " pathname: " + pathname);
      return [
        <Desktop>
          <Button
            style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
            // onClick={loadWeb3Modal}
          >
            <Link to="/">
              <img
                src="https://ucarecdn.com/54914285-8c51-44ad-b44f-b3b3c065e8d7/-/preview/-/resize/100x30/"
                alt="AVAVOX"
                width={100}
                height={30}
                onClick={handleClick}
              ></img>
            </Link>{" "}
            {/* <UpCircleFilled></UpCircleFilled> */}
          </Button>

          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <Link to="/traits">
              <img
                src="https://ucarecdn.com/23eaa413-b096-42ca-8f8e-be33836b4a35/-/resize/100x30/"
                alt="traits"
                width={100}
                height={30}
                onClick={(handleClick, scrollToTop)}
              ></img>
            </Link>
          </Button>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <Link to="/rewards">
              <img
                src="https://ucarecdn.com/e4cba88c-8912-442f-b396-cfe6a43290e4/-/preview/-/resize/100x30/"
                alt="rewards"
                width={100}
                height={30}
                onClick={(handleClick, scrollToTop)}
              ></img>
            </Link>
          </Button>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/avavoxnfts">
              <img
                src="https://ucarecdn.com/18bdabca-7e96-4b9e-b7e9-dcf7a7e21b1b/-/preview/-/resize/40x30/"
                alt="twitter"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/mMjU2EwS4W">
              <img
                src="https://ucarecdn.com/2baa9429-c436-4402-a758-eec93ad64d93/-/preview/-/resize/40x30/"
                alt="discord"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
        </Desktop>,

        <Mobile>
          <Button
            style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
            // onClick={loadWeb3Modal}
          >
            <Link to="/">
              <img
                src="https://ucarecdn.com/54914285-8c51-44ad-b44f-b3b3c065e8d7/-/preview/-/resize/100x30/"
                alt="AVAVOX"
                width={100}
                height={30}
                onClick={handleClick}
              ></img>
            </Link>{" "}
            {/* <UpCircleFilled></UpCircleFilled> */}
          </Button>

          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <Link to="/rewards">
              <img
                src="https://ucarecdn.com/e4cba88c-8912-442f-b396-cfe6a43290e4/-/preview/-/resize/100x30/"
                alt="rewards"
                width={100}
                height={30}
                onClick={(handleClick, scrollToTop)}
              ></img>
            </Link>
          </Button>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/avavoxnfts">
              <img
                src="https://ucarecdn.com/18bdabca-7e96-4b9e-b7e9-dcf7a7e21b1b/-/resize/40x30/"
                alt="twitter"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/mMjU2EwS4W">
              <img
                src="https://ucarecdn.com/2baa9429-c436-4402-a758-eec93ad64d93/-/resize/40x30/"
                alt="discord"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
        </Mobile>,

        <Tablet>
          <Button
            style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
            // onClick={loadWeb3Modal}
          >
            <Link to="/">
              <img
                src="https://ucarecdn.com/54914285-8c51-44ad-b44f-b3b3c065e8d7/-/resize/100x30/"
                alt="AVAVOX"
                width={100}
                height={30}
                onClick={handleClick}
              ></img>
            </Link>{" "}
            {/* <UpCircleFilled></UpCircleFilled> */}
          </Button>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <Link to="/rewards">
              <img
                src="https://ucarecdn.com/e4cba88c-8912-442f-b396-cfe6a43290e4/-/preview/-/resize/100x30/"
                alt="rewards"
                width={100}
                height={30}
                onClick={(handleClick, scrollToTop)}
              ></img>
            </Link>
          </Button>
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/avavoxnfts">
              <img
                src="https://ucarecdn.com/18bdabca-7e96-4b9e-b7e9-dcf7a7e21b1b/-/preview/-/resize/40x30/"
                alt="twitter"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
          ,
          <Button
            style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
            shape="round"
            size="large"
            type="text"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          >
            <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/mMjU2EwS4W">
              <img
                src="https://ucarecdn.com/2baa9429-c436-4402-a758-eec93ad64d93/-/preview/-/resize/40x30/"
                alt="discord"
                width={40}
                height={30}
              ></img>
            </a>
          </Button>
        </Tablet>,
      ];
    } else if (pathname === "/rewards") {
      console.log("current URL: " + currentURL + " pathname: " + pathname);
      return [
        <Button
          style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
          shape="round"
          size="large"
          type="text"
          /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          // onClick={loadWeb3Modal}
        >
          <Link to="/">
            <img
              src="https://ucarecdn.com/54914285-8c51-44ad-b44f-b3b3c065e8d7/-/preview/-/resize/100x30/"
              alt="AVAVOX"
              width={100}
              height={30}
              onClick={handleClick}
            ></img>
          </Link>{" "}
          {/* <UpCircleFilled></UpCircleFilled> */}
        </Button>,
        <Button
          style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
          shape="round"
          size="large"
          type="text"
          /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
        >
          <Link to="/traits">
            <img
              src="https://ucarecdn.com/23eaa413-b096-42ca-8f8e-be33836b4a35/-/resize/100x30/"
              alt="traits"
              width={100}
              height={30}
              onClick={(handleClick, scrollToTop)}
            ></img>
          </Link>
        </Button>,
        // <Button
        //   style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
        //   shape="round"
        //   size="large"
        //   type="text"
        //   /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
        // >
        //   <Link to="/minting">
        //     <img
        //       src="https://ucarecdn.com/20d2550f-fbaf-459b-bca9-5d31baf12e10/-/resize/100x30/"
        //       alt="minting"
        //       width={100}
        //       height={30}
        //       onClick={(handleClick, scrollToTop)}
        //     ></img>
        //   </Link>
        // </Button>,

        <Button
          style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
          shape="round"
          size="large"
          type="text"
          /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
        >
          <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/avavoxnfts">
            <img
              src="https://ucarecdn.com/18bdabca-7e96-4b9e-b7e9-dcf7a7e21b1b/-/preview/-/resize/40x30/"
              alt="twitter"
              width={40}
              height={30}
            ></img>
          </a>
        </Button>,

        <Button
          style={{ verticalAlign: "top", marginLeft: 4, marginTop: 2 }}
          shape="round"
          size="large"
          type="text"
          /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
        >
          <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/mMjU2EwS4W">
            <img
              src="https://ucarecdn.com/2baa9429-c436-4402-a758-eec93ad64d93/-/preview/-/resize/40x30/"
              alt="discord"
              width={40}
              height={30}
            ></img>
          </a>
        </Button>,
      ];
    }
  }
}
