import React from "react";
import { styled, keyframes } from "@stitches/react";

import { violet, blackA } from "@radix-ui/colors";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import hoverStyles from "../views/imagehover.module.css";

import { useMediaQuery } from "react-responsive";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const StyledContent = styled(TooltipPrimitive.Content, {
  borderRadius: 4,
  padding: "10px 15px",
  fontSize: 15,
  lineHeight: 1,
  color: violet.violet11,
  backgroundColor: "white",
  boxShadow: "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  userSelect: "none",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "100ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const StyledArrow = styled(TooltipPrimitive.Arrow, {
  fill: "white",
});

function Content({ children, ...props }) {
  return (
    <TooltipPrimitive.Portal>
      <StyledContent {...props}>
        {children}
        <StyledArrow />
      </StyledContent>
    </TooltipPrimitive.Portal>
  );
}

// Exports
export const Provider = TooltipPrimitive.Provider;
export const Tooltip = TooltipPrimitive.Root;
export const TooltipTrigger = TooltipPrimitive.Trigger;
export const TooltipContent = Content;

// Your app...
const PlusCircledIcon = styled("button", {
  all: "unset",
  fontFamily: "inherit",
  borderRadius: "100%",
  height: 35,
  width: 35,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  color: violet.violet11,
  backgroundColor: "#e84142",
  boxShadow: `0 2px 10px ${blackA.blackA7}`,
  "&:hover": { backgroundColor: violet.violet3 },
  "&:focus": { boxShadow: `0 0 0 2px black` },
});

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};

const RoadmapTooltipGladiatorGame = () => {
  return (
    <div>
      <Desktop>
        <Provider>
          <Tooltip delayDuration="100ms">
            <TooltipTrigger asChild>
              {/* <IconButton> */}
              <img
                className={hoverStyles.hover}
                style={{
                  boxShadow: "0 0 60px whitesmoke",
                  boxShadow: "20px 0 80px #0000FF",
                  boxShadow: "-20px 0 80px #0000FF",
                  boxShadow: "20px 0 300px #0000FF",
                  boxShadow: "-20px 0 300px #0000FF, 0 0 50px #0000FF,-10px 0 80px #0000FF,10px 0 80px #0000FF",
                }}
                height="45"
                width="150"
                src="https://ucarecdn.com/b62b1e0e-fd76-4e13-a09f-797be742822a/-/resize/150x45/"
                alt="GladiatorGame"
              ></img>
              {/* </IconButton> */}
            </TooltipTrigger>
            <TooltipContent sideOffset={5}>
              <p>We'll create a 3D auto battler with AvaVox characters as the gladiators.</p>
              <p>Bet & fight in verifiably random and fair battles to win big!</p>
              <p>Each gladiator will have their own traits, looks, personalities, and combat styles.</p>
            </TooltipContent>
          </Tooltip>
        </Provider>
      </Desktop>
      <Mobile>
        <Provider>
          <Tooltip delayDuration="100ms">
            <TooltipTrigger asChild>
              <PlusCircledIcon>
                <img
                  className={hoverStyles.hover}
                  style={{
                    boxShadow: "0 0 60px whitesmoke",
                    boxShadow: "20px 0 80px #0000FF",
                    boxShadow: "-20px 0 80px #0000FF",
                    boxShadow: "20px 0 300px #0000FF",
                    boxShadow: "-20px 0 300px #0000FF, 0 0 50px #0000FF,-10px 0 80px #0000FF,10px 0 80px #0000FF",
                    marginLeft: "120px",
                  }}
                  height="45"
                  width="150"
                  src="https://ucarecdn.com/b62b1e0e-fd76-4e13-a09f-797be742822a/-/resize/150x45/"
                  alt="GladiatorGame"
                ></img>
                {/* </IconButton> */}
              </PlusCircledIcon>
            </TooltipTrigger>
            <TooltipContent sideOffset={5}>
              <p>We'll create a 3D auto battler with AvaVox characters as the gladiators.</p>
              <p>Bet & fight in verifiably random and fair battles to win big!</p>
              <p>Each gladiator will have their own traits, looks, personalities, and combat styles.</p>
            </TooltipContent>
          </Tooltip>
        </Provider>
      </Mobile>

      <Tablet>
        <Provider>
          <Tooltip delayDuration="100ms">
            <TooltipTrigger asChild>
              <PlusCircledIcon>
                <img
                  className={hoverStyles.hover}
                  style={{
                    boxShadow: "0 0 60px whitesmoke",
                    boxShadow: "20px 0 80px #0000FF",
                    boxShadow: "-20px 0 80px #0000FF",
                    boxShadow: "20px 0 300px #0000FF",
                    boxShadow: "-20px 0 300px #0000FF, 0 0 50px #0000FF,-10px 0 80px #0000FF,10px 0 80px #0000FF",
                    marginLeft: "120px",
                  }}
                  height="45"
                  width="150"
                  src="https://ucarecdn.com/b62b1e0e-fd76-4e13-a09f-797be742822a/-/resize/150x45/"
                  alt="GladiatorGame"
                ></img>
                {/* </IconButton> */}
              </PlusCircledIcon>
            </TooltipTrigger>
            <TooltipContent sideOffset={5}>
              <p>We'll create a 3D auto battler with AvaVox characters as the gladiators.</p>
              <p>Bet & fight in verifiably random and fair battles to win big!</p>
              <p>Each gladiator will have their own traits, looks, personalities, and combat styles.</p>
            </TooltipContent>
          </Tooltip>
        </Provider>
      </Tablet>
    </div>
  );
};

export default RoadmapTooltipGladiatorGame;
