// import banner from "../banner.jfif";

import hoverStyles from "../views/imagehover.module.css";

// const PreloadImage = ReactPreloadImage;

function FloatingNFTs() {
  return (
    <div>
      <img
        width="750"
        height="420"
        className={hoverStyles.hover}
        src="https://ucarecdn.com/17f96443-7ef7-48d7-8e43-eef40e11524c/"
        alt="Floating NFTs"
        fetchpriority="high"
      ></img>
    </div>
  );
}

export default FloatingNFTs;
