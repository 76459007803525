import React from "react";
import { styled, keyframes } from "@stitches/react";

import { violet, blackA } from "@radix-ui/colors";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const StyledContent = styled(TooltipPrimitive.Content, {
  borderRadius: 4,
  padding: "10px 15px",
  fontSize: 15,
  lineHeight: 1,
  color: violet.violet11,
  backgroundColor: "white",
  boxShadow: "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  userSelect: "none",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "100ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const StyledArrow = styled(TooltipPrimitive.Arrow, {
  fill: "white",
});

function Content({ children, ...props }) {
  return (
    <TooltipPrimitive.Portal>
      <StyledContent {...props}>
        {children}
        <StyledArrow />
      </StyledContent>
    </TooltipPrimitive.Portal>
  );
}

// Exports
export const Provider = TooltipPrimitive.Provider;
export const Tooltip = TooltipPrimitive.Root;
export const TooltipTrigger = TooltipPrimitive.Trigger;
export const TooltipContent = Content;

// Your app...
const PlusCircledIcon = styled("button", {
  all: "unset",
  fontFamily: "inherit",
  borderRadius: "100%",
  height: 20,
  width: 20,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  color: violet.violet11,
  backgroundColor: "#e84142",
  boxShadow: `0 2px 10px ${blackA.blackA7}`,
  "&:hover": { backgroundColor: violet.violet3 },
  "&:focus": { boxShadow: `0 0 0 2px black` },
});

const OrcPopup = () => {
  return (
    <Provider>
      <Tooltip delayDuration="100ms">
        <TooltipTrigger asChild>
          <PlusCircledIcon>
            <img
              height="50"
              width="750"
              src="https://ucarecdn.com/8e780494-623b-4a65-ad9f-dbaded625f9e/-/resize/750x50/"
              alt="Orc Header"
            ></img>
          </PlusCircledIcon>
        </TooltipTrigger>
        <TooltipContent sideOffset={5}>
          {" "}
          <img
            height="150"
            width="150"
            src="https://ucarecdn.com/f2b37899-cab7-4b27-a603-b0917f33be5d/-/preview/-/resize/150x150/"
            alt="Orc Popup"
          ></img>
        </TooltipContent>
      </Tooltip>
    </Provider>
  );
};

export default OrcPopup;
