// import TraitBanner from "../TraitBanner.png";
import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};
function TraitBannerPicture() {
  return (
    <div>
      <Desktop>
        <img src="https://ucarecdn.com/236f8a15-080f-4454-938d-d4572dd9b554/-/resize/1100x350/" alt="TraitBanner" />
      </Desktop>
      <Mobile>
        <img src="https://ucarecdn.com/236f8a15-080f-4454-938d-d4572dd9b554/-/resize/750x200/" alt="TraitBanner" />
      </Mobile>
      <Tablet>
        <img src="https://ucarecdn.com/236f8a15-080f-4454-938d-d4572dd9b554/-/resize/750x200/" alt="TraitBanner" />
      </Tablet>
    </div>
  );
}

export default TraitBannerPicture;
