export default function WalletOptions() {
  return (
    <div>
      Install and enable either of these extensions to connect:
      <div>
        <a
          href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=ens"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          MetaMask{" "}
          <img
            src="https://ucarecdn.com/70dc1a0b-3dc1-43bf-8fdd-9e12bcff2a54/-/resize/20x20/"
            alt="Metamask"
            width={20}
            height={20}
          ></img>
        </a>
        <span style={{ fontWeight: "bold", marginTop: 5, marginBottom: 5 }}></span>{" "}
      </div>
      <div>
        <a
          href="https://chrome.google.com/webstore/detail/core/agoakfejjabomempkjlepdflaleeobhb?hl=ens"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          AVAX CORE{" "}
          <img
            src="https://ucarecdn.com/1869b6ef-3036-42a7-bb3e-841375f5b35d/-/resize/20x20/"
            alt="Core Wallet"
            width={20}
            height={20}
          ></img>
        </a>
        <span style={{ fontWeight: "bold", marginTop: 5, marginBottom: 5 }}></span>{" "}
      </div>
    </div>
  );
}
