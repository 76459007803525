import { Component } from "react";
import { Link } from "react-scroll";
import { Button } from "antd";

export default class ScrollingHomeSectionElf extends Component {
  render() {
    return (
      <ul
        style={{
          display: "flex",
          listStyle: "none",
          justifyContent: "space-around",
        }}
      >
        {/* <li>
          <Link activeClass="active" to="home" spy={true} smooth={true}>
            Home
          </Link>
        </li> */}
        <li>
          <Button type="text">
            <Link to="Elf" spy={true} smooth={true}>
              {" "}
              <img
                src="https://ucarecdn.com/6fbe53c4-cf4a-4fa6-9459-5f457614aeca/-/resize/200x50/"
                alt="Elf"
                width={200}
                height={50}
              ></img>
            </Link>
          </Button>
        </li>
      </ul>
    );
  }
}
