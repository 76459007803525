import AvavoxLogoSmall from "../components/AvavoxLogoSmall";
// displays a page header

export default function Header() {
  return (
    // <a href="https://github.com/austintgriffith/scaffold-eth" target="_blank" rel="noopener noreferrer">

    <div>
      {/* <span
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",

          fontSize: "20px",
          marginBottom: "10px",
          marginTop: "10px",
          padding: "50px",
          textAlign: "left",
        }}
      ></span> */}

      <span
        style={{
          justifyContent: "center",
          alignItems: "left",
          fontSize: "20px",
          // padding: "1000px",
          textAlign: "left",
          // marginTop: "50px",

          // paddingTop: "20px",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <p>
          <AvavoxLogoSmall></AvavoxLogoSmall>
        </p>
      </span>
    </div>
    // <PageHeader title="AvaVox" subTitle="Minting coming soon." style={{ cursor: "pointer" }} />

    // </a>
  );
}
